import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Form from './form.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'
import API from '../../services/api.jsx'

class ProjectsNew extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			breadcrumb: {title: 'Back to projects', path: '/projects'},
			entity: {
				name: null,
				slides: [],
				tags: []
			}
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	saveEntity(fields) {
		this.setState({ loading: true })
		const newFiles = fields.newFiles
		// Remove empty tag
		const newTags = []
		fields.tags.forEach(tag => {
			if (tag && tag !== '') {
				newTags.push(tag)
			}
		})

		const data = {
			name: fields.name,
			tags: newTags
		}

		API.createOrUpdateProject(data, newFiles).then(() => {
			toast.success('Project has been updated')
			this.props.history.push('/projects')
		}).catch((err) => {
			if (err.response.data.message === 'Project exists') {
				toast.error('Project exists, please check again')
				this.setState({ loading: false })
			} else {
				toast.error('An unexpected error has occured, please try again')
				this.setState({ loading: false })
			}
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'New project'} breadcrumb={this.state.breadcrumb} />
				<Content>
					<Form new cancel={() => this.props.history.push('/projects')} save={this.saveEntity} entity={this.state.entity} />
				</Content>
			</>
	}
}

export default ProjectsNew;
