import { React } from 'react';
import API from '../../services/api'

function PreviewImage(props) {
	const uri = props.uri

	return (
		<div className={"image-wrap" + (props.thinner ? ' thinner' : '')} key={props.id}>
            {props.label !== '' && <label>{props.label}</label>}
			<div className="image-icon-wrap">
				<img alt="preview" src={API.apiURL + '/' + uri} />
            	<p>{props.name}</p>
				<i onClick={() => props.removeImage()} className="icon-trash"></i>
			</div>
		</div>
	);
}

export default PreviewImage;