import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import moment from 'moment'
import { debounce } from 'lodash'
import { toast } from 'react-toastify'

class PortfolioBuild extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			clients: [],
			loading: true,
			filters: {
				name: ''
			},
            tabs: {
				links: [
					{ title: 'Create', path: '/portfolios/build' },
					{ title: 'Templates', path: '/portfolios/templates' }
				]
			},
			projects: {
				columns: [{
					label: 'Name',
					key: 'name'
                }, {
                    label: 'Tags',
                    key: 'tags',
                    view: function(tags) {
                        let tag = tags.map(tag => <div key={tag.id} className="tag">{tag.name}</div>)
                        return <div className="tags">
                            {tag}
                        </div>
                    }
				}, {
					label: 'Updated',
					key: 'updatedAt',
					view: function(field) {
						return moment(field).format('YYYY-MM-DD')
					}
				}],
				entities: [],
                filters: [
                    {
                        label: 'Project name',
                        onChange: debounce(value => {
                            if(value !== this.state.filters.name)
                                this.setState({ filters: { ...this.state.filters, name: value ? '/' + value + '/i' : '' }}, () => {
                                    this.loadEntities()
                                })
                        }, 500)
                    }, {
                        label: 'Search for a tag',
                        type: 'select',
                        value: null,
                        entities: [],
                        onChange: value => {
                            if (value !== this.state.filters.tags) {
                                if (value.id === '') {
                                    const newFilters = this.state.filters
                                    delete newFilters.tags
                                    this.setState({ filters: newFilters, projects: { ...this.state.projects, filters: [this.state.projects.filters[0], { ...this.state.projects.filters[1], value: value }] }}, () => {
                                        this.loadEntities()
                                    })
                                } else {
                                    this.setState({ filters: { ...this.state.filters, tags: value ? value.id : '' }, projects: { ...this.state.projects, filters: [this.state.projects.filters[0], { ...this.state.projects.filters[1], value: value }] }}, () => {
                                        this.loadEntities()
                                    })
                                }
                            }
                        }
                    }
                ],
				actions: [
                    {
                        type: 'deselectCheckboxes',
                        onClick: () => {
                            this.setState({...this.state, projects:{...this.state.projects, selectedItems: []}})
                        }

                    },
                    {
                        type: 'dropDown',
                        classList: 'blue dropdown-btn',
                        label: 'Export',
                        menuProps: {
                            items: [
                                {
                                    label: 'Deck',
                                    key: 'deck'
                                },
                                {
                                    label: 'Portfolio',
                                    key: 'portfolio'
                                }
                            ],
                            onClick: (e) => {
                                const entitiesForPdf = [...this.state.projects.selectedItems]

                                let selectOrNot = entitiesForPdf.length > 0 ? true : false

                                if (selectOrNot) {
                                    
                                    if (e.key === 'deck') {
                                        API.createPortfolio(entitiesForPdf, 'deck').then(res => {
                                            window.open(res)
                                        })
                                    } else if (e.key === 'portfolio') {
                                        API.createPortfolio(entitiesForPdf, 'portfolio').then(res => {
                                            window.open(res)
                                        })
                                    }
                                    
                                } else {
                                    toast.error('You didn\'t select any projects')
                                    this.setState({ loading: false })
                                }   
                            }
                        }
				    }
                ],
				selectedItems: [],
				multiSelect: true,
                isDraggable: true,
                onDragEnd: (param) => {
                    const entities = [...this.state.projects.entities]
                    if(param.destination) {
                        const originalIndex = param.source.index
                        const newIndex = param.destination.index
                        entities.splice(newIndex, 0, entities.splice(originalIndex, 1)[0])

                        const projects = this.state.projects
                        projects.entities = entities
                        this.setState({...this.state, projects: projects})

                        // Update projects order
                        let startIndex
                        let endIndex
                        if (originalIndex >= newIndex) {
                            startIndex = newIndex
                            endIndex = originalIndex
                        } else {
                            startIndex = originalIndex
                            endIndex = newIndex
                        }
                        for (let i = startIndex; i <= endIndex; i++) {
                            const data = { order: parseInt(i) + 1 }

                            API.updateProjectOrder(data, entities[i].id).then(() => {
                                console.log('Update project order successfully')
                            }).catch(() => {
                                toast.error('An unexpected error has occured when updating projects order, please try again')
                                this.setState({ loading: false })
                            })
                        }
                    }

                },
                onSelect: (key) => {
                    const selectedItems = [...this.state.projects.selectedItems]
                    const findIndex = selectedItems.indexOf(key)
                    if (findIndex > -1) {
                        selectedItems.splice(findIndex, 1);
                    } else {
                        selectedItems.push(key)
                    }

                    this.setState({...this.state, projects:{...this.state.projects, selectedItems}})
                }
			}
		}
	}

	componentDidMount() {
        API.getTags().then(tags => {
            // create index
            tags = [{ name: 'All tags', id: '' }].concat(tags)
            this.setState({ tags: tags, projects: { ...this.state.projects, filters: [this.state.projects.filters[0], { ...this.state.projects.filters[1], entities: tags, value: tags[0] }]} }, () => {
                this.loadEntities()
            })
        })
	}

	loadEntities() {
		API.getProjectsDetails(this.state.filters).then(entities => {
            this.setState({ ...this.state, projects: {...this.state.projects, entities: entities}, loading: false })
        })
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'Build'} tabs={this.state.tabs} />
				<Content>
					<Table settings={this.state.projects} />
				</Content>
			</>
	}
}

export default PortfolioBuild;
