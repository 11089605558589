import React from 'react';
import { NavLink, useLocation, useHistory } from "react-router-dom";
import User from '../../services/user.jsx'

function Nav(props) {
	let history = useHistory()
	const { pathname } = useLocation();

	function logout(e) {
		e.preventDefault()
		User.logout()
		history.push('/')
	}
	
	return (
		<nav>
			<ul>
				<li>
					<NavLink 
						activeClassName="active" 
						to="/portfolios/build"
						isActive={() => ['/portfolios/build', '/portfolios/templates'].includes(pathname)}
					>
						Build
					</NavLink>
				</li>
				<li>
					<NavLink
						activeClassName="active"
						to="/projects"
						isActive={() => ['/projects', '/tags'].includes(pathname)}
					>
						Manage
					</NavLink>
				</li>
				<li>
					<a href="#" onClick={logout}>
						Logout
					</a>
				</li>
			</ul> 
		</nav>
	);
}

export default Nav;
