import React from 'react'

class Form extends React.Component {
	constructor(props) {
		super(props)
		this.state = props.entity
	}

	updateField(e, key) {
		this.setState({ [key]: e.target.value })
	}

	updateBoolean(value, key) {
		this.setState({ [key]: value })
	}

	onCancel(e, callback) {
		e.preventDefault()
		callback()
	}

	onSubmit(e, callback) {
		e.preventDefault()
		callback()
	}

	render() {
		return (
			<form className="tags" onSubmit={(e) => this.onSubmit(e, () => this.props.save(this.state))}>
				<div className="actions clearfix">
					<div className="buttons clearfix">
						<div className="button grey" onClick={(e) => this.onCancel(e, () => this.props.cancel())}>Cancel</div>
						<button className="blue">Save</button>
					</div>
				</div>

				<div className="block">
					<div className="fields clearfix name">
						<h2>Name</h2>
						<div className="field">
							<input type="text"
								   name="name"
								   onChange={(e) => this.setState({...this.state, name: e.target.value})}
								   value={this.state.name || ''}/>
						</div>
					</div>
				</div>
			</form>
		)
	}
}


export default Form;