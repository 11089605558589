import React from 'react'
import Select from 'react-select'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space, Button } from 'antd';

class Table extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedItems: props.settings.selectedItems
		}
	}

	makeCheckbox = (key) => {
		let onChange = (e) => {
			e.stopPropagation()
			e.preventDefault()
			if(this.props.settings.onSelect) {
				this.props.settings.onSelect(key)
			}
		}
		
		return <label className="checkbox" onClick={(e) => onChange(e)}><input type="checkbox" onChange={()=>{}} checked={this.props.settings.selectedItems && this.props.settings.selectedItems.includes(key)} /><span></span></label>
	}

	deleteRow(e) {
		e.preventDefault()
		e.stopPropagation()
		return true
	}

	onDragEnd = (props) => {
	// the only one that is required
	this.props.settings.onDragEnd(props)
	};
	

	render() {
		return (
			<>
				<div className="actions clearfix">
					<div className="filters clearfix">
						{
							this.props.settings.filters.map((action, i) => {
								if(action.type === 'select')
									return <div key={`field-${i}`} className="search select">
										<Select
											value={action.value}
											onChange={action.onChange}
											options={action.entities}
											getOptionLabel={entity => entity.name}
											getOptionValue={entity => entity.id}
										/>
									</div>
								else
									return <div key={`field-${i}`} className="search icon-bigsearch">
										<input type="text" placeholder={action.label} onChange={(e) => action.onChange(e.target.value)} />
									</div>
							})
						}
					</div>

					<div className="buttons clearfix">
						{
							this.props.settings.actions.map((action, i) => {
								if(action.type === 'deselectCheckboxes') {
									return <div key={`field-${i}`} className="deselect">
										<span onClick={() => action.onClick()}></span>
										<label>Deselect all</label>
									</div>
								} else if (action.type === 'dropDown') {
									return <Dropdown key={`dropdown-${i}`} menu={action.menuProps}>
												<Button className={action.classList}>
													<Space>
														{action.label}
														<DownOutlined className='dropdown-icon' />
													</Space>
												</Button>
											</Dropdown>
								} else {
									return <button key={`button-${i}`} className={action.classList} onClick={action.onClick}>{action.label}</button>
								}
							})
						}
					</div>
				</div>

				<div className="table-wrapper">
					{
						this.props.settings.entities && <table className={this.props.settings.isDraggable ? 'draggable' : ''}>
							<thead>
								<tr>
									{
										this.props.settings.columns.map((column, key) => {
											return <td key={key}>{column.label}</td>
										})
									}
									{
										this.props.settings.isDraggable &&
										<td></td>
									}
								</tr>
							</thead>

							<DragDropContext
								onDragEnd={(param) => this.props.settings.onDragEnd(param)}
							>
								<Droppable droppableId={'droppable-area'}>
								{(provided, _) => (
								<tbody ref={provided.innerRef} {...provided.droppableProps} >
									{
										this.props.settings.entities.map((entity, rowKey) => (
											<Draggable draggableId={entity.id + 'draggable'} key={entity.id + 'draggable'} index={rowKey} isDragDisabled={!this.props.settings.isDraggable}>
												{(provided, snapshot) => (
													<tr key={entity.id} onClick={() => this.props.settings.onRowClick && this.props.settings.onRowClick(entity)} style={{background: snapshot.isDragging ? '#EAEAFF' : '#0000'}} ref={provided.innerRef} {...provided.draggableStyle} {...provided.draggableProps}>
														{
															this.props.settings.columns.map((column, key) => {
																if(column.action === 'delete')
																	return <td className={(column.className ? column.className : '')} key={entity.id + 'delete'} style={{width: '5%'}}><i className="icon-trash" onClick={(e) => this.deleteRow(e, entity.id, rowKey) && column.onClick && column.onClick(entity.id, rowKey)}></i></td>
																else if (column.action === 'edit')
																	return <td className={(column.className ? column.className : '')} key={entity.id + 'edit'} style={{width: '5%'}}><i className="icon-edit" onClick={(e) => column.editClicked(entity.id)}></i></td>
																else
																	return <td className={(column.className ? column.className : '')} key={entity.id + key}>{this.props.settings.multiSelect && key === 0 && this.makeCheckbox(entity.id)} {column.view ? column.view(entity[column.key], entity) : entity[column.key]}</td>
															})
														}
														{

															<td className="handler" {...provided.dragHandleProps}><span></span></td>
														}
													</tr>
												)}
											</Draggable>
										))
									}
									{provided.placeholder}
								</tbody>
								)}
								</Droppable>
							</DragDropContext>
						</table>
					}
					{ (!this.props.settings.entities || !this.props.settings.entities.length) && <div className="empty">There is no content in this list</div> }
				</div>
			</>
		);
	}

}


export default Table;

