import React from 'react';
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Form from './form.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'
import API from '../../services/api.jsx'

class ProjectEdit extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			breadcrumb: {title: 'Back to Project list', path: '/projects'},
			entity: null
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	componentDidMount() {
		API.getProject(this.props.match.params.projectId).then(project => this.setState({ loading: false, entity: project }))
	}

	deleteEntity = () => {
		var r = window.confirm("Are you sure you want to delete this project?");
		if (r === false) return
		this.setState({ loading: true })
		API.delete('projects', this.props.match.params.projectId).then(() => {
			toast.success('Project has been deleted')
			this.props.history.push('/projects')
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

	saveEntity(fields) {
		this.setState({ loading: true })
		const newFiles = fields.newFiles
		const slidesToKeep = fields.slides.filter(s => !s.temp).map(slide => {
			return slide.uri
		})
		// Remove empty tag
		const newTags = []
		fields.tags.forEach(tag => {
			if (tag && tag !== '') {
				newTags.push(tag)
			}
		})

		const data = {
			name: fields.name,
			tags: newTags,
			slidesToKeep
		}

		API.createOrUpdateProject(data, newFiles, this.props.match.params.projectId).then(() => {
			toast.success('Project has been updated')
			this.props.history.push('/projects')
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={this.state.entity.name} breadcrumb={this.state.breadcrumb} edit={() => (this.setState({popupVisible: true}))}/>
				<Content>
					<Form cancel={() => this.props.history.push('/projects')} save={this.saveEntity} entity={this.state.entity} popupVisible={this.state.popupVisible} togglePopup={() => this.setState({...this.state, popupVisible: !this.state.popupVisible})}/>
				</Content>
				<div className="actions under-content">
					<button onClick={() => {this.deleteEntity()}} className="red">Delete project</button>
				</div>
			</>
	}
}

export default ProjectEdit;
